




















































































































































































































































































































.courseCenter {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  .muKeCooperationBgBox {
    background: #f9f9f9;
    padding-bottom: 60px;

    .muKeCooperation {
      width: 1168px;
      margin: auto;
      .p_1 {
        text-align: center;
        color: #3f4652;
        font-size: 26px;
        margin-bottom: 40px;
        margin-top: 60px;
      }
      .muKeSettlementBenefits {
        display: flex;
        justify-content: space-between;
        .SettlementBenefitsBox {
          width: 277px;
          border-radius: 8px;
          &:hover {
            box-shadow: 0px 0px 38px 0px rgba(87,174,244,0.1);
          }
          .SettlementBenefitsBg {
            background-repeat: no-repeat;
            background-size: 100% 100%;
            height: 90px;
            display: flex;
            align-items: center;
            justify-content: center;
            h2 {
              color: #fff;
              text-align: center;
            }
          }
          .SettlementBenefits_bottom {
            background: #fff;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
            .SettlementBenefits_subTitle {
              padding: 40px 28px 48px 28px;
              text-align: center;
              color: #666;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
  //入驻资格
  .muKeCooperationEntryQualificationBox {
    background: #fff;
    padding-bottom: 60px;
    .EntryQualification {
      width: 1168px;
      margin: 0 auto;
      .muKeEntryQualification {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .EntryQualificationBox {
          width: 569px;
          height: 279px;
          box-shadow: 0px 0px 50px 0px rgba(87, 174, 244, 0.1);
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          &:nth-child(3) {
            margin-top: 30px;
          }
          &:nth-child(4) {
            margin-top: 30px;
          }
          .EntryQualificationContent {
            height: 279px;
            display: flex;
            flex-direction: column;
            align-content: center;
            justify-content: center;
            div {
              display: flex;
              align-items: center;
              justify-content: center;
            }
            h2 {
              text-align: center;
              padding: 10px 0;
            }
            p {
              text-align: center;
              color: #666;
              font-size:16px;
            }
          }
        }
      }
    }
  }
  //入驻流程
  .muKeCooperationSettlementProcessBox {
    background: #f9f9f9;
    padding-bottom: 60px;
    .SettlementProcess {
      width: 1168px;
      margin: auto;
      .muKeSettlementProcess {
        display: flex;
        justify-content: space-between;
        .SettlementProcessBox {
          display: flex;
          .SettlementProcessBoxImage {
            width: 122px;
            height: 122px;
            border-radius: 50%;
            background: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          p {
            text-align: center;
            font-weight: 500;
            color: #333;
            font-size: 17px;
            margin: 20px 0 47px;
            position: relative;
            &::before {
              content: "";
              position: absolute;
              width: 12px;
              height: 12px;
              border-radius: 50%;
              background: #2878ff;
              bottom: -54px;
              left: 50%;
              transition: (-50%);
              transform: translate(-50%);
            }
          }
        }
      }
    }
    .SettlementProcessLine {
      height: 1px;
      border-bottom: 2px dashed #d1d1d1;
    }
  }
  //入驻
  .muKeBgJoin {
    background-image: url("../../assets/mukeCooperation/bg.png");
    background-size: 100% 100%;
    text-align: center;
    color: #fff;
    h2 {
      padding-top: 94px;
      font-size: 45px;
    }
    p {
      padding: 30px 0 45px 0;
      font-size: 23px;
    }
  }
}
.courseCenter {
  /deep/.el-dialog__wrapper {
    z-index: 9999 !important;
  }
  /deep/.el-dialog {
    background: transparent;
    border-radius: 20px;
  }
  /deep/.el-dialog__header {
    background: #fff;
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
    padding: 20px 20px 10px;
    .el-dialog__title {
      color: #333;
      font-size: 20px;
    }
    .el-dialog__headerbtn {
      top: 38px;
      .el-dialog__close {
        color: #333;
      }
    }
    .el-dialog__headerbtn:focus .el-dialog__close,
    .el-dialog__headerbtn:hover .el-dialog__close {
      color: #333;
    }
  }
  /deep/.el-dialog__body {
    background: #fff;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    padding: 0 30px;
  }
  .contactTeacherPopup {
    padding-bottom: 57px;
    .dialogTop {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 45px;
      span {
        font-size: 23px;
        color: #333;
      }
    }
  }
}
